<template>
  <div style="padding: 80rem 0;background: #F9F9F9;display: flex;justify-content: center;min-height: 100vh;overflow-x: hidden;box-sizing: border-box">
    <div style="width: 1400rem">
      <i class="el-icon-back f-24" @click="$router.back()"></i>
      <span class="f-24 fw-600 ml-16">个人中心</span>

      <div class="mt-30 p-30" style="background: #FFF;box-sizing: border-box">
        <el-form :model="form" label-width="104rem" @submit.native.prevent>
          <div class="f-24 fw-400">基础信息</div>
          <div class="grid2 mt-24">
            <el-form-item label="姓名">
              <el-input v-if="first === 1" v-model="form.clerk_name" placeholder="请输入姓名"></el-input>
              <div v-else>{{ form.clerk_name }}</div>
            </el-form-item>
            <el-form-item label="性别">
              <el-select v-if="first === 1" v-model="form.gender" placeholder="请选择性别">
                <el-option v-for="item in genderArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
              <div v-else>{{ form.gender == 2 ? '男' : form.gender == 1 ? '女' : '保密' }}</div>
            </el-form-item>
            <el-form-item label="身份证号">
              <el-input v-if="first === 1" v-model="form.id_card" maxlength="18" placeholder="请输入身份证号" @blur="id_cardBlur" @input="form.id_card = form.id_card.replace(/[^0-9X]/g, '')"></el-input>
              <div v-else>{{ form.id_card }}</div>
            </el-form-item>
            <el-form-item label="出生日期">
              <el-date-picker v-if="first === 1" v-model="form.birthday" format="yyyy-MM-dd" placeholder="请选择出生日期" type="date" value-format="yyyy-MM-dd"></el-date-picker>
              <div v-else>{{ form.birthday }}</div>
            </el-form-item>
            <el-form-item label="民族">
              <el-select v-if="first === 1" v-model="form.nation" placeholder="请选择民族">
                <el-option v-for="item in nationArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
              <div v-else>{{ nationArr.filter(item => item.id === form.nation)[0]?.name }}</div>
            </el-form-item>
            <el-form-item label="宗教信仰">
              <el-input v-if="first === 1" v-model="form.religion" maxlength="10" placeholder="请输入宗教信仰"></el-input>
              <div v-else>{{ form.religion }}</div>
            </el-form-item>
            <el-form-item label="最高学历">
              <el-select v-if="first === 1" v-model="form.education" placeholder="请选择最高学历">
                <el-option v-for="item in educationLevels" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
              <div v-else>{{ form.education == 7 ? '博士研究生' : form.education == 6 ? '硕士研究生' : form.education == 5 ? '本科' : form.education == 4 ? '专科' : form.education == 3 ? '高中' : form.education == 2 ? '初中' : form.education == 1 ? '小学' : '' }}</div>
            </el-form-item>
            <div></div>
            <el-form-item label="毕业院校">
              <el-input v-if="first === 1" v-model="form.graduated_from" placeholder="请输入毕业院校"></el-input>
              <div v-else>{{ form.graduated_from }}</div>
            </el-form-item>
            <el-form-item label="专业">
              <el-input v-if="first === 1" v-model="form.major" placeholder="请输入专业"></el-input>
              <div v-else>{{ form.major }}</div>
            </el-form-item>
          </div>

          <div class="f-24 fw-400">入职信息</div>
          <div class="grid2 mt-24">
            <el-form-item label="教学学科">
              <el-cascader v-if="first === 1" v-model="form.subject" :options="subjectArr" :props="{value:'id',label:'subject_name',children:'child'}" @change="handleChange"></el-cascader>
              <div v-else>{{ subjectArr.filter(i => i.id == form.fist_subject_id)[0]?.subject_name }}/{{ subjectArr.filter(i => i.id == form.fist_subject_id)[0]?.child.filter(i => i.id == form.subject_id)[0]?.subject_name }}</div>
            </el-form-item>
            <el-form-item label="入职时间">
              <el-date-picker v-if="first === 1" v-model="form.entry_time" format="yyyy-MM-dd" placeholder="请选择出生日期" type="date" value-format="yyyy-MM-dd"></el-date-picker>
              <div v-else>{{ form.entry_time }}</div>
            </el-form-item>
            <el-form-item label="联系电话">
              <el-input v-if="first === 1" v-model="form.mobile" maxlength="11" placeholder="请输入联系电话" x></el-input>
              <div v-else>{{ form.mobile }}</div>
            </el-form-item>
          </div>

          <div class="f-24 fw-400">个人简介</div>
          <div class="mt-24">
            <el-form-item label="优势标签">
              <el-tag v-for="(i) in form.tags" closable style="margin-right: 8rem" @close="onDeleteTag(i)">{{ i }}</el-tag>
              <el-input v-if="inputVisible" ref="saveTagInput" v-model="inputValue" class="input-new-tag" placeholder="请输入标签" size="small" @blur="handleInputConfirm" @keyup.enter.native="handleInputConfirm"/>
              <el-button v-else class="button-new-tag" size="small" @click="showInput">+</el-button>
              <div style="font-weight: 400;font-size: 12px;color: #AEAEAE;margin-top: -8rem;">按回车键保存</div>
            </el-form-item>
            <el-form-item label="个人简介">
              <el-input v-if="first === 1" v-model="form.summary" maxlength="300" placeholder="请输入个人简介" show-word-limit type="textarea"></el-input>
              <span v-else style="border-radius: 4px 4px 4px 4px;border: 1px solid #DBDDE4;padding: 14px;line-height:18px;display: inline-block">
                {{ form.summary }}
              </span>
            </el-form-item>
            <el-form-item label="个人头像">
              <el-upload v-if="first === 1" :action="$_axios2.defaults.baseURL + '/api/upload/upload'" :before-upload="beforeAvatarUpload"
                         :headers="{ authorization: 'Bearer ' + $store.getters.token }"
                         :multiple="false"
                         :on-error="onChangea"
                         :on-success="onChange"
                         :show-file-list="false"
                         class="upload-demo"
                         list-type="picture" name="upload">
                <el-button size="small">点击上传</el-button>
                <div slot="tip" class="el-upload__tip" style="margin-top: -8rem;color:#C0C0C0">仅支持上传jpg，png格式的图片，且图片大小不超过20M</div>
              </el-upload>
              <el-image v-if="form.avator" :src="form.avator" style="width: 92px; height: 92px;border-radius: 4px"></el-image>
            </el-form-item>
            <el-form-item label="个人照片">
              <el-upload v-if="first === 1" :action="$_axios2.defaults.baseURL + '/api/upload/upload'" :before-upload="beforeAvatarUpload"
                         :headers="{ authorization: 'Bearer ' + $store.getters.token }"
                         :multiple="false"
                         :on-error="onChangea"
                         :on-success="onChange_"
                         :show-file-list="false"
                         class="upload-demo"
                         list-type="picture" name="upload">
                <el-button size="small">点击上传</el-button>
                <div slot="tip" class="el-upload__tip" style="margin-top: -8rem;color:#C0C0C0">仅支持上传jpg，png格式的图片，且图片大小不超过20M</div>
              </el-upload>
              <el-image v-if="form.photo" :src="form.photo" style="width: 150px; height: 96px;border-radius: 4px"></el-image>
            </el-form-item>
            <el-form-item label="审核状态">
              <el-button size="small">{{ form.checked == 0 ? '待审核' : form.checked == 1 ? '审核通过' : form.checked == 2 ? '审核不通过' : '' }}</el-button>
            </el-form-item>
          </div>
        </el-form>

        <el-button v-if="first === 1" type="primary" @click="onSubmit">提交</el-button>
        <el-button v-else type="primary" @click="first=1">编辑</el-button>
        <el-button @click.prevent="$router.back()">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  data() {
    return {
      form: {},
      value: '',
      inputValue: '',
      options: [],
      nationArr: [],
      subjectArr: [],
      inputVisible: false,
      "educationLevels": [
        {"value": 7, "label": "博士研究生"},
        {"value": 6, "label": "硕士研究生"},
        {"value": 5, "label": "本科"},
        {"value": 4, "label": "专科"},
        {"value": 3, "label": "高中"},
        {"value": 2, "label": "初中"},
        {"value": 1, "label": "小学"}
      ],
      genderArr: [
        {value: 2, label: '男'},
        {value: 1, label: '女'},
        {value: 0, label: '保密'},
      ],
      first: 1
    }
  },
  methods: {
    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      if (this.inputValue.length <= 0) return this.inputVisible = false
      this.form.tags.push(this.inputValue)
      this.inputValue = ''
      this.inputVisible = false
    },
    onDeleteTag(tag) {
      this.form.tags.splice(this.form.tags.indexOf(tag), 1);
    },
    handleChange(val) {
      console.log(val)
    },
    isPureChinese(text) {
      const chineseRegex = /^[\u4e00-\u9fa5]+$/;
      return chineseRegex.test(text);
    },
    id_cardBlur() {
      console.log(this.getBirthdayFromIdCard(this.form.id_card))
      if (this.getBirthdayFromIdCard(this.form.id_card) !== '') this.form.birthday = this.getBirthdayFromIdCard(this.form.id_card)
      this.$forceUpdate()
    },
    getBirthdayFromIdCard(idCard) {
      if (idCard.length === 18) {
        let birthDate = idCard.substr(6, 8);
        let year = birthDate.substr(0, 4);
        let month = birthDate.substr(4, 2);
        let day = birthDate.substr(6, 2);
        return `${year}-${month}-${day}`;
      }
      return '';
    },

    onChange(file) {
      this.form.avator = file.data.file_addr
    },
    onChange_(file) {
      this.form.photo = file.data.file_addr
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        return this.$message.error('上传头像图片只能是 pjpeg或jpeg或jpg或png 格式!')
      }
      if (!isLt2M) {
        return this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    onChangea() {
      this.$methods.console.error('上传出错请重试')
    },
    onSubmit() {
      this.$_axios2.post('api/teacher/account/edit', this.form).then(res => {
        this.$message.success('修改成功')
        this.$_axios2.get('api/teacher/account/info').then(res => {
          this.form = res.data.data
          this.form.education = Number(res.data.data.education)
          this.form.nation = Number(res.data.data.nation)
          this.form.subject = [String(res.data.data.fist_subject_id), String(res.data.data.subject_id)]
          if (res.data.data.education == 0) this.form.education = ''
          if (res.data.data.nation == 0) this.form.nation = ''
          this.first = this.form.first
        })
      })
    },
  },
  created() {
    this.$_axios2.get('api/teacher/account/info').then(res => {
      this.form = res.data.data
      this.form.education = Number(res.data.data.education)
      this.form.nation = Number(res.data.data.nation)
      this.form.subject = [String(res.data.data.fist_subject_id), String(res.data.data.subject_id)]
      if (res.data.data.education == 0) this.form.education = ''
      if (res.data.data.nation == 0) this.form.nation = ''
      this.first = this.form.first
    })
    this.$_axios2.get('api/common/nation').then(res => {
      this.nationArr = res.data.data
    })
    this.$_axios.get('site/subject').then(res => {
      this.subjectArr = res.data.data
    })
  }
}
</script>
<style lang="scss" scoped>
.grid2 {
  display: grid;
  grid-template-columns: 574rem 1fr;
}
</style>
<style>
.t-watermark-box {
  width: 100% !important;
}
</style>
